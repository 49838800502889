import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import useAccessControl from '../../hooks/useAccessControl';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectNotifications } from '../../store/selectors/notifications';
import { selectMachines } from '../../store/selectors/machine';
import { selectTickets, selectLoading, selectActionSuccessfully } from '../../store/selectors/tickets';
import { fetchTicketsPending, resetActionSuccessfullyPending } from '../../store/slices/tickets';
import { Box, Button, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import Filter, { TFilterValues } from './Filter';
import Loader from '../../components/Loader';
import CustomSnackbar from '../../components/Snackbar';
import { accessKeys, routes } from '../../constants';
import TicketsTable from './TicketsTable';
import NewTicket from './NewTicket';
import { createFilterTimeRange } from '../../helpers';
import { TICKET_STATUS, TMachine } from '../../types';
import { fetchMachinesPending } from '../../store/slices/machine';
import { fetchNotificationsPending } from '../../store/slices/notifications';
import { selectFeatureFlags } from '../../store/selectors/user';
import { Outlet, useLocation } from 'react-router-dom';
import useCalculatedDimensions from '../../hooks/useCalculatedDimensions';

/* ------- Styles ------- */
const NoTickets = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '70vh',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

/* ------- Components ------- */
const Service = () => {
  const { AccessControl, userAccess } = useAccessControl();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const featureFlags = useSelector(selectFeatureFlags);
  const tickets = useSelector(selectTickets);
  const loading = useSelector(selectLoading);
  const actionSuccessfully = useSelector(selectActionSuccessfully);
  const notifications = useSelector(selectNotifications);
  const machines = useSelector(selectMachines);

  const breadcrumbRef = document.getElementById('breadcrumb');
  const { height } = useCalculatedDimensions([breadcrumbRef]);
  const [isSubRoute, setIsSubRoute] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [openNewTicket, setOpenNewTicket] = useState<boolean>(false);
  const [specificMachine, setSpecificMachine] = useState<TMachine | undefined>(undefined);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    machine: 'all',
    urgency: 'all',
    serviceProvider: 'all',
    contact: 'all',
    created: 'all',
    status: 'all',
  });

  useEffect(() => {
    const isLabRoute = location.pathname.includes('/service/');
    const isRouteWithId = location.pathname.lastIndexOf('/') < location.pathname.length - 1;
    setIsSubRoute(isLabRoute && isRouteWithId);
  }, [location]);

  useEffect(() => {
    if (filterValues.machine !== 'all') {
      setSpecificMachine(machines?.find((machine) => machine.serialNumber === filterValues.machine) || undefined);
    } else {
      setSpecificMachine(undefined);
    }
  }, [filterValues.machine]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!machines) {
      dispatch(fetchMachinesPending());
    }

    if (!notifications.list) {
      dispatch(fetchNotificationsPending({ page: { pageNumber: 0, pageSize: 1000 } }));
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // create request body
    const requestFilter = {
      timeRange: (filterValues.created !== 'all' && createFilterTimeRange(filterValues.created)) || undefined,
      status: TICKET_STATUS[filterValues.status],
      machineSerialNumbers:
        filterValues.machine === 'all' || filterValues.machine === undefined ? undefined : [filterValues.machine],
      urgency: filterValues.urgency === 'all' || filterValues.urgency === undefined ? undefined : filterValues.urgency,
      serviceProvider:
        filterValues.serviceProvider === 'all' || filterValues.serviceProvider === undefined
          ? undefined
          : filterValues.serviceProvider,
      contact: filterValues.contact === 'all' || filterValues.contact === undefined ? undefined : filterValues.contact,
    };

    // remove all undefined properties from request body
    Object.keys(requestFilter).forEach((key) => requestFilter[key] === undefined && delete requestFilter[key]);

    // fetch notifications
    dispatch(
      fetchTicketsPending({
        page: {
          pageNumber: page + 1, // page for pagination starts at 0
          pageSize: rowsPerPage,
        },
        orderBy: {
          columnName: 'id',
          direction: 'desc',
        },
        filterGroups: Object.keys(requestFilter).map((key) => ({
          columnName: key,
          filters: [
            {
              operator: 'eq',
              value: requestFilter[key],
            },
          ],
        })),
      }),
    );
  }, [dispatch, page, rowsPerPage, filterValues]);

  const handleSnackbarClose = () => {
    dispatch(resetActionSuccessfullyPending());
  };

  return (
    <Layout noChildPadding>
      <AccessControl accessKeys={[accessKeys.SERVICE_ALLOWED]} redirectUrl={routes.UNAUTHORIZED}>
        {!isSubRoute ? (
          <Box padding='24px 32px' sx={{ height, overflow: 'auto' }}>
            <Typography variant='h5' marginBottom={2}>
              Tickets
            </Typography>
            <Box>
              <Box display='flex' gap='32px' justifyContent='space-between' margin='0 0 16px 0'>
                <Filter
                  contactPersons={
                    tickets.list?.map((ticket) => ticket.assignedUser).filter((user) => user !== null) || []
                  }
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  machinesFilterActive
                />
                {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
                  <Button
                    variant='contained'
                    color='info'
                    startIcon={<AddIcon />}
                    onClick={() => setOpenNewTicket(true)}
                  >
                    {t('tickets:createTicket:title:Create')}
                  </Button>
                )}
              </Box>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {!tickets.list?.length ? (
                    <NoTickets>
                      <ArticleOutlinedIcon />
                      <Typography variant='h6' component='h6'>
                        {t('tickets:noTickets:title')}
                      </Typography>
                      <Typography>{t('tickets:noTickets:text')}</Typography>
                    </NoTickets>
                  ) : (
                    <TicketsTable
                      tickets={tickets.list}
                      ticketsTotal={tickets.total}
                      pagination={{
                        page,
                        onChangePage: (value) => setPage(value),
                        rowsPerPage,
                        onChangeRowsPerPage: (value) => setRowsPerPage(value),
                      }}
                    />
                  )}
                </>
              )}

              {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
                <NewTicket
                  open={openNewTicket}
                  onClose={() => {
                    setOpenNewTicket(false);
                  }}
                  actionType='Create'
                  machine={specificMachine}
                />
              )}

              <CustomSnackbar
                severity='success'
                open={actionSuccessfully === 'Create' || actionSuccessfully === 'Update'}
                onClose={handleSnackbarClose}
                message={t(`tickets:createTicket:successMessages:${actionSuccessfully}`)}
              />
            </Box>
          </Box>
        ) : (
          <Outlet />
        )}
      </AccessControl>
    </Layout>
  );
};

export default Service;
