import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsTable from '../../../../Notifications/NotificationsTable';
import { Box, Button, Typography, styled } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { selectMachineDetails, selectMachineNotifications } from '../../../../../store/selectors/machine';
import { fetchMachineNotificationsPending } from '../../../../../store/slices/machine';
import { ISSUE_STATUS, NOTIFICATION_TYPE, TTimeFilterValue } from '../../../../../types';
import Filter, { TFilterValues } from '../../../../Notifications/Filter';
import { createFilterTimeRange } from '../../../../../helpers';
import NewTicket from '../../../../Service/NewTicket';
import useAccessControl from '../../../../../hooks/useAccessControl';
import Loader from '../../../../../components/Loader';
import { selectFeatureFlags } from '../../../../../store/selectors/user';

/* ------- Styles ------- */
const NoNotifications = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '100%',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

/* ------- Components ------- */
const Notifications: React.FC = () => {
  const { userAccess } = useAccessControl();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const machineDetails = useSelector(selectMachineDetails);
  const notifications = useSelector(selectMachineNotifications);
  const featureFlags = useSelector(selectFeatureFlags);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [openNewTicket, setOpenNewTicket] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    notificationType: 'all',
    started: 'all',
    issueStatus: 'all',
    ticketStatus: 'all',
  });

  useEffect(() => {
    if (machineDetails) {
      // create request body
      const requestBody = {
        page: {
          pageNumber: page + 1, // page for pagination starts at 0
          pageSize: rowsPerPage,
        },
        timeRange:
          (filterValues.started !== 'all' && createFilterTimeRange(filterValues.started as TTimeFilterValue)) ||
          undefined,
        issueStatus: ISSUE_STATUS[filterValues.issueStatus],
        notificationType: NOTIFICATION_TYPE[filterValues.notificationType],
      };

      // remove all undefined properties from request body
      Object.keys(requestBody).forEach((key) => requestBody[key] === undefined && delete requestBody[key]);

      // fetch notifications
      dispatch(
        fetchMachineNotificationsPending({
          serialNumber: machineDetails.serialNumber,
          body: requestBody,
        }),
      );
    }
  }, [dispatch, machineDetails, page, rowsPerPage, filterValues]);

  return notifications.loading ? (
    <Loader />
  ) : (
    <>
      <Box display='flex' gap='32px' justifyContent='space-between' margin='0 0 16px 0'>
        <Filter filterValues={filterValues} setFilterValues={setFilterValues} />
        {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
          <Button variant='contained' color='info' startIcon={<AddIcon />} onClick={() => setOpenNewTicket(true)}>
            {t('tickets:createTicket:title:Create')}
          </Button>
        )}
      </Box>

      {!notifications.list?.length ? (
        <NoNotifications>
          <NotificationsNoneIcon />
          <Typography variant='h6' component='h6'>
            {t('notifications:noNotifications:title')}
          </Typography>
          <Typography>{t('notifications:noNotifications:text')}</Typography>
        </NoNotifications>
      ) : (
        <NotificationsTable
          notifications={notifications.list}
          notificationsTotal={notifications.total}
          pagination={{
            page,
            onChangePage: (value) => setPage(value),
            rowsPerPage,
            onChangeRowsPerPage: (value) => setRowsPerPage(value),
          }}
        />
      )}

      {machineDetails && userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
        <NewTicket
          open={openNewTicket}
          onClose={() => {
            setOpenNewTicket(false);
          }}
          actionType='Create'
          machine={{
            id: machineDetails.id,
            name: machineDetails.name,
            type: machineDetails.type,
            serialNumber: machineDetails.serialNumber,
          }}
        />
      )}
    </>
  );
};

export default Notifications;
