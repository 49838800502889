import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filter, { TFilterValues } from './Filter';
import NotificationsTable from './NotificationsTable';
import NewTicket from '../Service/NewTicket';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { Box, Button, Tab, Tabs, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch, useSelector } from '../../hooks/redux';
import { selectNotifications } from '../../store/selectors/notifications';
import { fetchNotificationsPending } from '../../store/slices/notifications';
import useCalculatedDimensions from '../../hooks/useCalculatedDimensions';
import { ISSUE_STATUS, NOTIFICATION_TYPE, TMachine } from '../../types';
import { createFilterTimeRange } from '../../helpers';
import { selectMachines } from '../../store/selectors/machine';
import useAccessControl from '../../hooks/useAccessControl';
import { accessKeys, routes } from '../../constants';
import { selectFeatureFlags } from '../../store/selectors/user';
import CustomSnackbar from '../../components/Snackbar';
import { selectActionSuccessfully } from '../../store/selectors/tickets';
import { resetActionSuccessfullyPending } from '../../store/slices/tickets';

/* ------- Styles ------- */
const TabPanel = styled('div')<{ height: number }>(({ height }) => ({
  padding: '32px 4px',
  overflow: 'auto',
  height,
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const NoNotifications = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  verticalAlign: 'middle',
  height: '100%',

  '& svg': {
    fontSize: '74px',
    color: theme.palette.grey[80],
  },

  '& h6': {
    color: theme.palette.black[60],
    fontWeight: 700,
  },

  '& p': {
    color: theme.palette.black[60],
  },
}));

/* ------- Types ------- */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  height: number;
}

/* ------- Components ------- */
const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, height, ...other }) => (
  <TabPanel role='tabpanel' hidden={value !== index} height={height} {...other}>
    {value === index && children}
  </TabPanel>
);

const Notifications = () => {
  const { AccessControl, userAccess } = useAccessControl();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const featureFlags = useSelector(selectFeatureFlags);
  const actionSuccessfully = useSelector(selectActionSuccessfully);
  const notifications = useSelector(selectNotifications);
  const machines = useSelector(selectMachines);

  const tabNav = useRef(null);
  const title = useRef(null);
  const breadcrumbRef = document.getElementById('breadcrumb');
  const { height } = useCalculatedDimensions([breadcrumbRef, title.current, tabNav.current]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [openNewTicket, setOpenNewTicket] = useState<boolean | string>(false);
  const [specificMachine, setSpecificMachine] = useState<TMachine | undefined>(undefined);
  const [filterValues, setFilterValues] = useState<TFilterValues>({
    machine: 'all',
    notificationType: 'all',
    started: 'all',
    issueStatus: 'all',
    ticketStatus: 'all',
  });

  useEffect(() => {
    if (filterValues.machine !== 'all') {
      setSpecificMachine(machines?.find((machine) => machine.serialNumber === filterValues.machine) || undefined);
    } else {
      setSpecificMachine(undefined);
    }
  }, [filterValues.machine]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // create request body
    const requestBody = {
      page: {
        pageNumber: page + 1, // page for pagination starts at 0
        pageSize: rowsPerPage,
      },
      timeRange: (filterValues.started !== 'all' && createFilterTimeRange(filterValues.started)) || undefined,
      issueStatus: ISSUE_STATUS[filterValues.issueStatus],
      notificationType: NOTIFICATION_TYPE[filterValues.notificationType],
      machineSerialNumbers:
        filterValues.machine === 'all' || filterValues.machine === undefined ? undefined : [filterValues.machine],
    };

    // remove all undefined properties from request body
    Object.keys(requestBody).forEach((key) => requestBody[key] === undefined && delete requestBody[key]);

    // fetch notifications
    dispatch(fetchNotificationsPending({ ...requestBody }));
  }, [dispatch, page, rowsPerPage, filterValues]);

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = () => {
    dispatch(resetActionSuccessfullyPending());
  };

  return (
    <Layout>
      <AccessControl accessKeys={[accessKeys.NOTIFICATIONS_ALLOWED]} redirectUrl={routes.UNAUTHORIZED}>
        <Typography ref={title} variant='h5' marginBottom={2}>
          {t('notifications:title')}
        </Typography>
        <Tabs
          ref={tabNav}
          value={activeTab}
          onChange={handleTabChange}
          textColor='secondary'
          indicatorColor='secondary'
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.grey[20]}` }}
        >
          <Tab label={<span>{t('notifications:tabs:notifications')}</span>} />
          <Tab label={<span>{t('notifications:tabs:settings')}</span>} />
        </Tabs>

        <CustomTabPanel value={activeTab} index={0} height={height - 74 /* tab height with padding */}>
          <Box display='flex' gap='32px' justifyContent='space-between' margin='0 0 16px 0'>
            <Filter filterValues={filterValues} setFilterValues={setFilterValues} machinesFilterActive />
            {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
              <Button variant='contained' color='info' startIcon={<AddIcon />} onClick={() => setOpenNewTicket(true)}>
                {t('tickets:createTicket:title:Create')}
              </Button>
            )}
          </Box>
          {notifications.loading ? (
            <Loader />
          ) : (
            <>
              {!notifications.list?.length ? (
                <NoNotifications>
                  <NotificationsNoneIcon />
                  <Typography variant='h6' component='h6'>
                    {t('notifications:noNotifications:title')}
                  </Typography>
                  <Typography>{t('notifications:noNotifications:text')}</Typography>
                </NoNotifications>
              ) : (
                <NotificationsTable
                  notifications={notifications.list}
                  notificationsTotal={notifications.total}
                  pagination={{
                    page,
                    onChangePage: (value) => setPage(value),
                    rowsPerPage,
                    onChangeRowsPerPage: (value) => setRowsPerPage(value),
                  }}
                  onCreateTicket={(notificationId) => setOpenNewTicket(notificationId)}
                />
              )}

              {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
                <NewTicket
                  open={!!openNewTicket}
                  onClose={() => {
                    setOpenNewTicket(false);
                  }}
                  actionType='Create'
                  machine={specificMachine}
                  ticketDefaultValues={{
                    relatedError: openNewTicket as string,
                  }}
                />
              )}
            </>
          )}

          <CustomSnackbar
            severity='success'
            open={!!actionSuccessfully}
            onClose={handleSnackbarClose}
            message={t('tickets:createTicket:successMessages:Create')}
          />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1} height={height - 74 /* tab height with padding */}>
          <Typography variant='h6' sx={{ color: (theme) => theme.palette.grey[60] }}>
            Coming soon..
          </Typography>
        </CustomTabPanel>
      </AccessControl>
    </Layout>
  );
};

export default Notifications;
