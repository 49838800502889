import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../hooks/redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import locale_de from 'dayjs/locale/de';
import locale_en from 'dayjs/locale/en';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload, { FileInfo } from '../../../components/FileUpload';
import { formatDate } from '../../../helpers';
import dayjs from 'dayjs';
import { ISSUE_STATUS, NOTIFICATION_TYPE, TServiceProvider, TTicket, TUrgency } from '../../../types';
import { createTicketPending } from '../../../store/slices/tickets';
import { selectUsers } from '../../../store/selectors/user';
import { selectNotifications } from '../../../store/selectors/notifications';
import { selectMachines } from '../../../store/selectors/machine';

/* ------- Styles ------- */
const TicketInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '20px',
  backgroundColor: theme.palette.grey[10],
  padding: '24px 20px',
  borderRadius: '4px',
  height: 'calc(100% - 24px)',
}));

const TicketFormGroup = styled(FormGroup)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: '24px',
});

const RelatedSelect = styled(MenuItem)({
  display: 'block',
  width: '490px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ButtonSelection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
});

const SelectButton = styled(Button)<{ selected?: boolean }>(({ selected, theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: selected ? `1px solid ${theme.palette.info.main}` : `1px solid ${theme.palette.grey[100]}`,
  color: theme.palette.black[100],
  fontSize: '14px',
  fontWeight: 400,

  '&:hover': {
    backgroundColor: theme.palette.others.blue[10],
    border: selected ? `1px solid ${theme.palette.info.main}` : `1px solid ${theme.palette.others.blue[40]}`,
  },
}));

const RequiredMarker = styled('sup')(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '16px',
  fontWeight: 500,
  marginLeft: '4px',
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  gap: '24px',
  padding: '24px 32px',
  borderTop: `1px solid ${theme.palette.grey[20]}`,
}));

/* ------- Types ------- */
export type TNewTicket = {
  id: string;
  relatedError: string;
  relatedMachine: string;
  title: string;
  description: string;
  serviceProvider: TServiceProvider | null;
  urgency: TUrgency | null;
  assignee: string;
  contactPerson: string;
  attachments: File[] | TTicket['fileAttachments'];
  storedAttachments: TTicket['fileAttachments'];
  fileNamesToDelete: string[];
  includedErrorProtocol: boolean;
  timePeriodFrom: dayjs.Dayjs | null;
  timePeriodTo: dayjs.Dayjs | null;
};

interface INewTicketProps {
  open: boolean;
  onClose: () => void;
  actionType: 'Create' | 'Update';
  machine?: {
    id: string;
    name: string;
    type: string;
    serialNumber: string;
  };
  ticketDefaultValues?: Partial<TNewTicket>;
}

/* ------- Components ------- */
const NewTicket: React.FC<INewTicketProps> = ({ open, onClose, actionType, machine, ticketDefaultValues }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const notifications = useSelector(selectNotifications);
  const machines = useSelector(selectMachines);
  const [ticket, setTicket] = useState<TNewTicket>({
    id: '',
    relatedError: '',
    relatedMachine: '',
    title: '',
    description: '',
    serviceProvider: null,
    urgency: null,
    assignee: '',
    contactPerson: '',
    attachments: [],
    storedAttachments: [],
    fileNamesToDelete: [],
    includedErrorProtocol: false,
    timePeriodFrom: null,
    timePeriodTo: null,
  });

  useEffect(() => {
    if (ticketDefaultValues) {
      setTicket({
        ...ticket,
        ...ticketDefaultValues,
      });
    }
  }, [ticketDefaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const isValid =
    ticket.title.length && ticket.description.length && ticket.serviceProvider !== null && ticket.urgency !== null;

  const cleanAndClose = () => {
    setTicket({
      id: '',
      relatedError: '',
      relatedMachine: '',
      title: '',
      description: '',
      serviceProvider: null,
      urgency: null,
      assignee: '',
      contactPerson: '',
      attachments: [],
      storedAttachments: [],
      fileNamesToDelete: [],
      includedErrorProtocol: false,
      timePeriodFrom: null,
      timePeriodTo: null,
    });
    onClose();
  };

  const handleCreateTicket = () => {
    const data = new FormData();

    data.append('id', ticket.id);
    data.append('title', ticket.title);
    data.append('description', ticket.description);
    data.append('serviceProvider', ticket.serviceProvider || '');
    data.append('urgency', ticket.urgency || '');

    if (machine) {
      Object.keys(machine).forEach((attr) => {
        data.append(`machineDetails.${attr}`, machine[attr]);
      });
    }

    if (ticket.relatedMachine.length) {
      const selectedMachine = machines?.filter((machine) => machine.id === ticket.relatedMachine)[0];
      selectedMachine &&
        Object.keys(selectedMachine).forEach((attr) => {
          data.append(`machineDetails.${attr}`, selectedMachine[attr]);
        });
    }

    if (ticket.attachments.length) {
      ticket.attachments.forEach((file) => {
        data.append('fileAttachments', file);
      });
    }

    if (ticket.fileNamesToDelete.length) {
      ticket.fileNamesToDelete.forEach((fileName) => {
        data.append('fileNamesToDelete', fileName);
      });
    }

    if (ticket.assignee.length || ticket.contactPerson.length) {
      const assignedUser = ticket.assignee.length
        ? users?.filter((user) => user.userId === ticket.assignee)[0]
        : users?.filter((user) => user.userId === ticket.contactPerson)[0];

      if (assignedUser) {
        data.append('assignedUser.id', assignedUser.userId);
        data.append('assignedUser.displayName', assignedUser.displayName);
        data.append('assignedUser.emailAddress', assignedUser.email);
      }
    }

    if (ticket.relatedError) {
      const notification = notifications.list?.filter((noti) => noti.issueCode.code === ticket.relatedError)[0];
      const machineFromNotification = machines?.find(
        (machine) => machine.serialNumber === notification?.machineSerialNumbers?.[0],
      );

      if (machineFromNotification) {
        Object.keys(machineFromNotification).forEach((attr) => {
          data.append(`machineDetails.${attr}`, machineFromNotification[attr]);
        });
      }

      if (notification) {
        data.append('relatedError.messageId', notification.messageId);
        data.append('relatedError.message', notification.message);
      }
    }

    dispatch(createTicketPending({ ticket: data, action: actionType }));
    cleanAndClose();
  };

  const handleChangeField = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    setTicket({ ...ticket, [event.target.name]: event.target.value });
  };

  /* const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicket({ ...ticket, [event.target.name]: event.target.checked });
  }; */

  const handleButtonSelection = (name: string, value: string) => {
    if (name === 'serviceProvider' && value === 'ExternalService') {
      setTicket({ ...ticket, [name]: value, relatedError: '' });
    } else {
      setTicket({ ...ticket, [name]: value });
    }
  };

  const handleDateChange = (name: string, value: dayjs.Dayjs | null) => {
    setTicket({ ...ticket, [name]: value });
  };

  const removeStoredFiles = (file: { fileName: string; fileSize: number }) => {
    setTicket({ ...ticket, fileNamesToDelete: [...ticket.fileNamesToDelete, file.fileName] });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={machine ? 'md' : 'sm'}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: '700',
          color: (theme) => theme.palette.black[100],
        }}
      >
        {t(`tickets:createTicket:title:${actionType}`)}
        <IconButton
          size='small'
          aria-label='close'
          color='inherit'
          onClick={cleanAndClose}
          sx={{ '&:hover': { background: 'none' } }}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '576px' }}>
        <Grid container spacing={4}>
          <Grid xs={machine ? 7 : 12}>
            <Box display='flex' gap={2}>
              {!machine && (
                <Box sx={{ width: ticket.serviceProvider !== 'ExternalService' ? '50%' : '100%' }}>
                  <TicketFormGroup>
                    <InputLabel shrink htmlFor='relatedMachine'>
                      {t('tickets:createTicket:relatedMachine')}
                    </InputLabel>
                    <Select
                      fullWidth
                      size='small'
                      id='relatedMachine'
                      name='relatedMachine'
                      color='secondary'
                      value={ticket.relatedMachine}
                      onChange={handleChangeField}
                    >
                      <RelatedSelect value=''>{t('tickets:createTicket:relatedErrorEmpty')}</RelatedSelect>
                      {machines?.map((machine) => (
                        <RelatedSelect key={machine.id} value={machine.id} title={machine.name}>
                          {machine.name}
                        </RelatedSelect>
                      ))}
                    </Select>
                  </TicketFormGroup>
                </Box>
              )}

              {ticket.serviceProvider !== 'ExternalService' && (
                <Box sx={{ width: machine ? '100%' : '50%' }}>
                  <TicketFormGroup>
                    <InputLabel shrink htmlFor='relatedError'>
                      {t('tickets:createTicket:relatedError')}
                    </InputLabel>
                    <Select
                      fullWidth
                      size='small'
                      id='relatedError'
                      name='relatedError'
                      color='secondary'
                      value={ticket.relatedError}
                      onChange={handleChangeField}
                    >
                      <RelatedSelect value=''>{t('tickets:createTicket:relatedErrorEmpty')}</RelatedSelect>
                      {notifications.list
                        ?.filter(
                          (notification) =>
                            notification.issueCode.type === NOTIFICATION_TYPE.ERROR &&
                            notification.issueStatus === ISSUE_STATUS.ACTIVE,
                        )
                        .map((notification) => (
                          <RelatedSelect
                            key={notification.issueCode.code}
                            value={notification.issueCode.code}
                            title={`${notification.issueCode.code}: ${notification.message}`}
                          >
                            {notification.issueCode.code}: {notification.message}
                          </RelatedSelect>
                        ))}
                    </Select>
                  </TicketFormGroup>
                </Box>
              )}
            </Box>
            <TicketFormGroup>
              <InputLabel shrink htmlFor='title'>
                {t('tickets:createTicket:ticketTitle')}
                <RequiredMarker>*</RequiredMarker>
              </InputLabel>
              <TextField
                autoFocus
                fullWidth
                size='small'
                type='text'
                id='title'
                name='title'
                placeholder={t('tickets:createTicket:titlePlaceholder')}
                color='secondary'
                value={ticket.title}
                onChange={handleChangeField}
              />
            </TicketFormGroup>
            <TicketFormGroup>
              <InputLabel shrink htmlFor='description'>
                {t('tickets:createTicket:description')}
                <RequiredMarker>*</RequiredMarker>
              </InputLabel>
              <TextField
                fullWidth
                size='small'
                type='text'
                multiline
                minRows={4}
                id='description'
                name='description'
                placeholder={t('tickets:createTicket:descriptionPlaceholder')}
                color='secondary'
                value={ticket.description}
                onChange={handleChangeField}
              />
            </TicketFormGroup>
            <TicketFormGroup>
              <InputLabel shrink>
                {t('tickets:createTicket:serviceProvider')}
                <RequiredMarker>*</RequiredMarker>
              </InputLabel>
              <ButtonSelection>
                <SelectButton
                  variant='outlined'
                  onClick={() => handleButtonSelection('serviceProvider', 'InternalService')}
                  selected={ticket.serviceProvider === 'InternalService'}
                  sx={{ width: '50%' }}
                >
                  {t('tickets:createTicket:internalService')}
                </SelectButton>
                <SelectButton
                  variant='outlined'
                  onClick={() => handleButtonSelection('serviceProvider', 'ExternalService')}
                  selected={ticket.serviceProvider === 'ExternalService'}
                  sx={{ width: '50%' }}
                >
                  {t('tickets:createTicket:optotechService')}
                </SelectButton>
              </ButtonSelection>
            </TicketFormGroup>
            {ticket.serviceProvider === 'InternalService' && (
              <TicketFormGroup>
                <InputLabel shrink htmlFor='assignee'>
                  {t('tickets:createTicket:assignee')}
                </InputLabel>
                <Select
                  fullWidth
                  size='small'
                  id='assignee'
                  name='assignee'
                  color='secondary'
                  value={ticket.assignee}
                  onChange={handleChangeField}
                >
                  {users?.map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.displayName || `${user.firstName} ${user.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </TicketFormGroup>
            )}
            {ticket.serviceProvider === 'ExternalService' && (
              <TicketFormGroup>
                <InputLabel shrink htmlFor='contactPerson'>
                  {t('tickets:createTicket:contactPerson')}
                  <RequiredMarker>*</RequiredMarker>
                </InputLabel>
                <Select
                  fullWidth
                  size='small'
                  id='contactPerson'
                  name='contactPerson'
                  color='secondary'
                  value={ticket.contactPerson}
                  onChange={handleChangeField}
                >
                  {users?.map((user) => (
                    <MenuItem key={user.userId} value={user.userId}>
                      {user.displayName || `${user.firstName} ${user.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </TicketFormGroup>
            )}
            <TicketFormGroup>
              <InputLabel shrink>
                {t('tickets:createTicket:urgency')}
                <RequiredMarker>*</RequiredMarker>
              </InputLabel>
              <ButtonSelection>
                <SelectButton
                  variant='outlined'
                  onClick={() => handleButtonSelection('urgency', 'Low')}
                  selected={ticket.urgency === 'Low'}
                  sx={{ width: '33%' }}
                >
                  {t('tickets:createTicket:urgencyLow')}
                </SelectButton>
                <SelectButton
                  variant='outlined'
                  onClick={() => handleButtonSelection('urgency', 'Medium')}
                  selected={ticket.urgency === 'Medium'}
                  sx={{ width: '33%' }}
                >
                  {t('tickets:createTicket:urgencyMedium')}
                </SelectButton>
                <SelectButton
                  variant='outlined'
                  onClick={() => handleButtonSelection('urgency', 'High')}
                  selected={ticket.urgency === 'High'}
                  sx={{ width: '33%' }}
                >
                  {t('tickets:createTicket:urgencyHigh')}
                </SelectButton>
              </ButtonSelection>
            </TicketFormGroup>
          </Grid>
          {machine && (
            <Grid xs={5}>
              <TicketInfo>
                <Typography variant='body1' fontWeight={600}>
                  {t('tickets:createTicket:machineDetails:label')}
                </Typography>

                <Box>
                  <Typography variant='body2' color={(theme) => theme.palette.black[60]}>
                    {t('tickets:createTicket:machineDetails:name')}
                  </Typography>
                  <Typography variant='body2' fontWeight={400}>
                    {machine?.name}
                  </Typography>
                </Box>
                <Divider />

                <Box>
                  <Typography variant='body2' color={(theme) => theme.palette.black[60]}>
                    {t('tickets:createTicket:machineDetails:type')}
                  </Typography>
                  <Typography variant='body2' fontWeight={400}>
                    {machine?.type}
                  </Typography>
                </Box>
                <Divider />

                <Box>
                  <Typography variant='body2' color={(theme) => theme.palette.black[60]}>
                    {t('tickets:createTicket:machineDetails:serialNumber')}
                  </Typography>
                  <Typography variant='body2' fontWeight={400}>
                    {machine?.serialNumber}
                  </Typography>
                </Box>
                <Divider />

                <Box>
                  <Typography variant='body2' color={(theme) => theme.palette.black[60]}>
                    {t('tickets:createTicket:machineDetails:lastMaintenance')}
                  </Typography>
                  <Typography variant='body2' fontWeight={400}>
                    {formatDate(new Date().toDateString(), 'de', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </Typography>
                </Box>
                <Divider />
              </TicketInfo>
            </Grid>
          )}
        </Grid>

        <FileUpload
          selectFileText={t('tickets:createTicket:fileUpload:label')}
          infoText={t('tickets:createTicket:fileUpload:infoText')}
          onChange={(files: File[]) => setTicket({ ...ticket, attachments: files })}
        />
        <Box display='flex' flexDirection='column' gap={1} marginTop={1}>
          {ticket.storedAttachments
            .filter((file) => !ticket.fileNamesToDelete.includes(file.fileName))
            .map((file) => (
              <FileInfo
                key={file.fileName}
                fileName={file.fileName}
                fileSize={file.fileSize}
                onRemove={() => removeStoredFiles(file)}
              />
            ))}
        </Box>

        {/* <FormControlLabel
          label={
            <Tooltip title={t('tickets:createTicket:includeErrorProtTooltip')} placement='top' arrow>
              <Typography component='span' sx={{ borderBottom: (theme) => `1px dashed ${theme.palette.grey[60]}` }}>
                {t('tickets:createTicket:includeErrorProt')}
              </Typography>
            </Tooltip>
          }
          labelPlacement='start'
          sx={{ marginLeft: 0, marginBottom: '16px', marginTop: '16px' }}
          control={
            <Switch
              color='secondary'
              name='includedErrorProtocol'
              checked={ticket.includedErrorProtocol}
              onChange={handleChangeSwitch}
            />
          }
        /> */}

        {ticket.includedErrorProtocol && (
          <TicketFormGroup>
            <InputLabel shrink>
              {t('tickets:createTicket:timePeriod')}
              <RequiredMarker>*</RequiredMarker>
            </InputLabel>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={language === 'de' ? locale_de.name : locale_en.name}
            >
              <Box display='flex' gap={2}>
                <DatePicker
                  value={ticket.timePeriodFrom}
                  onChange={(value) => handleDateChange('timePeriodFrom', value)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      color: 'secondary',
                    },
                  }}
                />
                <DatePicker
                  value={ticket.timePeriodTo}
                  onChange={(value) => handleDateChange('timePeriodTo', value)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      color: 'secondary',
                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
          </TicketFormGroup>
        )}
      </DialogContent>
      <Actions>
        <Button variant='outlined' color='secondary' onClick={cleanAndClose} fullWidth>
          {t('common:cancel')}
        </Button>
        <Button variant='contained' onClick={handleCreateTicket} fullWidth color='secondary' disabled={!isValid}>
          {t(`tickets:createTicket:submit:${actionType}`)}
        </Button>
      </Actions>
    </Dialog>
  );
};

export default NewTicket;
