import { createSlice } from '@reduxjs/toolkit';
import { ApolloError } from '@apollo/client/errors';
import { TTicket } from '../../types';

export type TPayloadTickets = {
  page: {
    pageNumber: number;
    pageSize: number;
  };
  orderBy: {
    columnName: string;
    direction: 'asc' | 'desc';
  };
  filterGroups: {
    columnName: string;
    filters: {
      operator: 'eq';
      value: string;
    }[];
  }[];
};

export type TPayloadCreateTicket = {
  ticket: FormData;
  action: 'Create' | 'Update';
};

export type TPayloadTicketDetails = {
  ticketId: string;
};

export type TPayloadTicketStatus = {
  ticketId: string;
  ticketStateAction: 'Approve' | 'Accept' | 'Cancel';
};

export type TPayloadTicketComment = {
  ticketId: string;
  comment: {
    id: string | null;
    comment: string;
  };
};

export type TPayloadTicketCommentDelete = {
  ticketId: string;
  commentId: string;
};

type TPayloadError = {
  error: ApolloError;
};

export interface ITickets {
  tickets: {
    list: TTicket[] | null;
    ticketDetails: TTicket | null;
    history: TTicket[] | null;
    total: number;
  };
  actionSuccessfully: 'Create' | 'Update' | 'DeleteComment' | TPayloadTicketStatus['ticketStateAction'] | null;
  loading: boolean;
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: ITickets = {
  tickets: {
    list: null,
    ticketDetails: null,
    history: null,
    total: 0,
  },
  actionSuccessfully: null,
  loading: false,
  error: null,
};

/*-----------------SLICE-----------------*/
const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    /*-----------------Pending Actions/Reducers-----------------*/
    fetchTicketsPending: (
      state,
      _action: {
        payload: TPayloadTickets;
      },
    ) => ({
      ...state,
      loading: true,
    }),
    createTicketPending: (
      state,
      _action: {
        payload: TPayloadCreateTicket;
      },
    ) => state,
    fetchTicketDetailsPending: (
      state,
      _action: {
        payload: TPayloadTicketDetails;
      },
    ) => ({
      ...state,
      loading: true,
    }),
    fetchTicketHistoryPending: (
      state,
      _action: {
        payload: TPayloadTicketDetails;
      },
    ) => state,
    setTicketStatusPending: (
      state,
      _action: {
        payload: TPayloadTicketStatus;
      },
    ) => state,
    addCommentPending: (
      state,
      _action: {
        payload: TPayloadTicketComment;
      },
    ) => state,
    deleteCommentPending: (
      state,
      _action: {
        payload: TPayloadTicketCommentDelete;
      },
    ) => state,
    resetActionSuccessfullyPending: (state) => {
      return {
        ...state,
        actionSuccessfully: null,
      };
    },

    /*-----------------Success Actions/Reducers-----------------*/
    fetchTicketsSuccess: (state, { payload }: { payload: { list: TTicket[]; total: number } }) => {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          list: payload.list,
          total: payload.total,
        },
        loading: false,
      };
    },
    createTicketSuccess: (state, { payload }: { payload: { ticket: TTicket; action: 'Create' | 'Update' } }) => {
      const notUpdatedTickets = state.tickets.list?.filter((ticket) => ticket.id !== payload.ticket.id) || [];
      return {
        ...state,
        tickets: {
          ...state.tickets,
          list: [...notUpdatedTickets, payload.ticket].sort((a, b) => (a.id < b.id ? 1 : -1)),
          ticketDetails: payload.ticket,
          total: state.tickets.total + 1,
        },
        actionSuccessfully: payload.action,
      };
    },
    fetchTicketDetailsSuccess: (state, { payload }: { payload: TTicket }) => {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDetails: payload,
        },
        loading: false,
      };
    },
    fetchTicketHistorySuccess: (state, { payload }: { payload: TTicket[] }) => {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          history: payload,
        },
        loading: false,
      };
    },
    setTicketStatusSuccess: (
      state,
      { payload }: { payload: { ticket: TTicket; ticketStateAction: TPayloadTicketStatus['ticketStateAction'] } },
    ) => {
      const notUpdatedTickets = state.tickets.list?.filter((ticket) => ticket.id !== payload.ticket.id) || [];
      return {
        ...state,
        tickets: {
          ...state.tickets,
          list: [...notUpdatedTickets, payload.ticket].sort((a, b) => (a.id < b.id ? 1 : -1)),
          ticketDetails: payload.ticket,
        },
        actionSuccessfully: payload.ticketStateAction,
      };
    },
    addCommentSuccess: (state, { payload }: { payload: TTicket }) => {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDetails: payload,
        },
      };
    },
    deleteCommentSuccess: (state, { payload }: { payload: TTicket }) => {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          ticketDetails: payload,
        },
        actionSuccessfully: 'DeleteComment',
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    actionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        actionSuccessfully: null,
        loading: false,
        error: payload.error,
      };
    },
  },
});

export const {
  resetActionSuccessfullyPending,
  fetchTicketsPending,
  fetchTicketsSuccess,
  createTicketPending,
  createTicketSuccess,
  fetchTicketDetailsPending,
  fetchTicketDetailsSuccess,
  fetchTicketHistoryPending,
  fetchTicketHistorySuccess,
  setTicketStatusPending,
  setTicketStatusSuccess,
  addCommentPending,
  addCommentSuccess,
  deleteCommentPending,
  deleteCommentSuccess,
  actionFailed,
} = ticketsSlice.actions; // eslint-disable-line

export default ticketsSlice.reducer;
