import { createSlice } from '@reduxjs/toolkit';
import { TAuthUser, TUser, TUserRole } from '../../types';
import { ApolloError } from '@apollo/client/errors';

/*-----------------TYPES-----------------*/
type TFetchUserData = {
  user: TUser;
};

type TFetchUsers = {
  users: TUser[];
};

type TFetchUserRoles = {
  roles: TUserRole[];
};

export type TCreateUser = {
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  phone?: string;
  roleIds: string[];
};

export type TUpdateUserRoles = {
  userId: string;
  roleIds: string[];
};

export type TDeleteUser = {
  userId: string;
};

type TActionFlags = {
  createSuccess?: boolean;
  deleteSuccess?: boolean;
};

type TPayloadError = {
  error: ApolloError;
};

export interface IUser {
  authUser: TAuthUser | null;
  user: TUser | null;
  users: TUser[] | null;
  roles: TUserRole[] | null;
  featureFlags: string[];
  actionFlags: {
    createSuccess: boolean;
    deleteSuccess: boolean;
  };
  error: ApolloError | null;
}

/*-----------------INITIAL STATE-----------------*/
export const initialState: IUser = {
  authUser: null,
  user: null,
  users: null,
  roles: null,
  featureFlags: [],
  actionFlags: {
    createSuccess: false,
    deleteSuccess: false,
  },
  error: null,
};

/*-----------------SLICE-----------------*/
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthUser: (state, { payload }: { payload: TAuthUser }) => {
      return {
        ...state,
        authUser: payload,
      };
    },

    /*-----------------Pending Actions/Reducers-----------------*/
    fetchUserDataPending: (state) => state,
    fetchUsersPending: (state) => state,
    fetchUserRolesPending: (state) => state,
    updateUserRolesPending: (
      state,
      _action: {
        payload: TUpdateUserRoles;
      },
    ) => state,
    createUserPending: (
      state,
      _action: {
        payload: TCreateUser;
      },
    ) => state,
    deleteUserPending: (
      state,
      _action: {
        payload: TDeleteUser;
      },
    ) => state,
    resetActionFlagsPending: (state, action: { payload: TActionFlags }) => {
      const { payload } = action;
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          ...payload,
        },
      };
    },
    fetchFeatureFlagsPending: (state) => state,

    /*-----------------Success Actions/Reducers-----------------*/
    fetchUserDataSuccess: (state, { payload }: { payload: TFetchUserData }) => {
      return {
        ...state,
        user: payload.user,
      };
    },
    fetchUsersSuccess: (state, { payload }: { payload: TFetchUsers }) => {
      return {
        ...state,
        users: payload.users,
      };
    },
    fetchUserRolesSuccess: (state, { payload }: { payload: TFetchUserRoles }) => {
      return {
        ...state,
        roles: payload.roles,
      };
    },
    createUserSuccess: (state) => {
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          createSuccess: true,
        },
      };
    },
    deleteUserSuccess: (state) => {
      return {
        ...state,
        actionFlags: {
          ...state.actionFlags,
          deleteSuccess: true,
        },
      };
    },
    fetchFeatureFlagsSuccess: (state, { payload }: { payload: string[] }) => {
      return {
        ...state,
        featureFlags: payload,
      };
    },

    /*-----------------Failed Actions/Reducers-----------------*/
    actionFailed: (state, { payload }: { payload: TPayloadError }) => {
      return {
        ...state,
        actionFlags: {
          createSuccess: false,
          deleteSuccess: false,
        },
        error: payload.error,
      };
    },
  },
});

export const {
  setAuthUser,
  fetchUserDataPending,
  fetchUsersPending,
  updateUserRolesPending,
  deleteUserPending,
  fetchUserRolesPending,
  createUserPending,
  resetActionFlagsPending,
  fetchFeatureFlagsPending,
  fetchUserDataSuccess,
  fetchUsersSuccess,
  fetchUserRolesSuccess,
  createUserSuccess,
  deleteUserSuccess,
  fetchFeatureFlagsSuccess,
  actionFailed,
} = userSlice.actions; // eslint-disable-line

export default userSlice.reducer;
