import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContextMenu, { ContextButton } from '../../../components/ContextMenu';
import { TICKET_STATUS, TTicket, TServiceProvider, TUrgency } from '../../../types';
import { formatDate } from '../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../hooks/redux';
import { setTicketStatusPending } from '../../../store/slices/tickets';
import NewTicket from '../NewTicket';
import useAccessControl from '../../../hooks/useAccessControl';
import { selectFeatureFlags } from '../../../store/selectors/user';
import DeleteDialog from '../../../components/DeleteDialog';

/* ------- Styles ------- */
const statusBaseStyle = {
  fontSize: '14px',
  fontWeight: 500,
};

const ColorDot = styled('div')<{ color: 'error' | 'warning' | 'success' }>(({ color, theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: theme.palette[color].main,
}));

const TableAvatar = styled(Avatar)(({ theme }) => ({
  display: 'inline-flex',
  width: '36px',
  height: '36px',
  fontSize: '12px',
  fontWeight: 500,
  backgroundColor: theme.palette.magenta[20],
  color: theme.palette.magenta[100],
}));

/* ------- Types ------- */
interface Column {
  id: 'ticketId' | 'title' | 'machine' | 'urgency' | 'serviceProvider' | 'contact' | 'created' | 'status' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: number | string) => string | JSX.Element;
}

interface Data {
  ticketId: string;
  title: string;
  machine: string;
  urgency: TUrgency;
  serviceProvider: TServiceProvider;
  contact: string;
  created: string;
  status: TICKET_STATUS;
  actions: JSX.Element;
}

interface ActionsProps {
  ticketStatus: TICKET_STATUS | null;
  ticketId: string;
  openEditTicket: () => void;
  openCancelTicket: () => void;
}

interface ITicketsTableProps {
  tickets: TTicket[];
  ticketsTotal: number;
  pagination: {
    page: number;
    onChangePage: (value: number) => void;
    rowsPerPage: number;
    onChangeRowsPerPage: (value: number) => void;
  };
}

/* ------- Components ------- */
const Actions: React.FC<ActionsProps> = ({ ticketStatus, ticketId, openEditTicket, openCancelTicket }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const getAllowedActions = () => {
    if (ticketStatus === TICKET_STATUS.NEW) {
      return [
        {
          name: t('tickets:table:actions:edit'),
          callback: openEditTicket,
        },
        {
          name: t('tickets:table:actions:cancel'),
          callback: openCancelTicket,
        },
      ];
    } else if (ticketStatus === TICKET_STATUS.PROGRESS) {
      return [
        {
          name: t('tickets:table:actions:resolve'),
          callback: () => dispatch(setTicketStatusPending({ ticketId, ticketStateAction: 'Accept' })),
        },
        {
          name: t('tickets:table:actions:cancel'),
          callback: openCancelTicket,
        },
      ];
    } else if (ticketStatus === TICKET_STATUS.PENDING) {
      return [
        {
          name: t('tickets:table:actions:approve'),
          callback: () => dispatch(setTicketStatusPending({ ticketId, ticketStateAction: 'Approve' })),
        },
        {
          name: t('tickets:table:actions:edit'),
          callback: openEditTicket,
        },
        {
          name: t('tickets:table:actions:cancel'),
          callback: openCancelTicket,
        },
      ];
    }

    return [];
  };

  return ticketStatus === TICKET_STATUS.RESOLVED || ticketStatus === TICKET_STATUS.CANCELED ? null : (
    <Box>
      <ContextButton
        ref={menuAnchorRef}
        testId='tableActions'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <MoreHorizIcon />
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={getAllowedActions()}
        hoverColor={(theme) => theme.palette.others.blue[10]}
      />
    </Box>
  );
};

const TicketsTable: React.FC<ITicketsTableProps> = ({ tickets, ticketsTotal, pagination }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { userAccess } = useAccessControl();
  const featureFlags = useSelector(selectFeatureFlags);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ticketToEdit, setTicketToEdit] = useState<string | null>(null);
  const [ticketToCancel, setTicketToCancel] = useState<string | null>(null);
  const [tableRows, setTableRows] = useState<Data[]>([]);
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    pagination.onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    pagination.onChangeRowsPerPage(parseInt(event.target.value, 10));
    pagination.onChangePage(0);
  };

  const handleCancelTicket = () => {
    dispatch(setTicketStatusPending({ ticketId: ticketToCancel as string, ticketStateAction: 'Cancel' }));
  };

  const createData = (ticketId, title, machine, urgency, serviceProvider, contact, created, status, actions): Data => {
    return { ticketId, title, machine, urgency, serviceProvider, contact, created, status, actions };
  };

  const generateRows: (data: TTicket[]) => Data[] = (data) =>
    data.map(({ id, title, machineDetails, urgency, serviceProvider, assignedUser, createdAt, state }) =>
      createData(
        id,
        title,
        machineDetails?.name || '--',
        urgency,
        serviceProvider,
        assignedUser?.displayName || null,
        createdAt,
        state,
        <Actions
          ticketStatus={state}
          ticketId={id}
          openEditTicket={() => setTicketToEdit(id)}
          openCancelTicket={() => setTicketToCancel(id)}
        />,
      ),
    );

  const renderChip = (status: TICKET_STATUS) => {
    switch (status) {
      case TICKET_STATUS.NEW:
        return (
          <Chip
            label={t('tickets:table:status:new')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.blue[100],
              border: '1px solid rgba(1, 121, 231, .3)',
              backgroundColor: (theme) => theme.palette.others.blue[10],
            }}
          />
        );
      case TICKET_STATUS.PROGRESS:
        return (
          <Chip
            label={t('tickets:table:status:progress')}
            sx={{
              color: (theme) => theme.palette.others.brown[100],
              border: '1px solid rgba(255, 173, 0, .3)',
              backgroundColor: (theme) => theme.palette.others.orange[10],
              fontSize: '14px',
              fontWeight: 500,
            }}
          />
        );
      case TICKET_STATUS.RESOLVED:
        return (
          <Chip
            label={t('tickets:table:status:resolved')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.green[100],
              border: '1px solid rgba(74, 166, 105, 1)',
              backgroundColor: (theme) => theme.palette.others.green[10],
            }}
          />
        );
      case TICKET_STATUS.CANCELED:
        return (
          <Chip
            label={t('tickets:table:status:canceled')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.grey[100],
              border: (theme) => `1px solid ${theme.palette.grey[20]}`,
              backgroundColor: (theme) => theme.palette.grey[10],
            }}
          />
        );
      case TICKET_STATUS.PENDING:
        return (
          <Chip
            label={t('tickets:table:status:pending')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.purple[100],
              border: (theme) => `1px solid ${theme.palette.purple[20]}`,
              backgroundColor: (theme) => theme.palette.purple[10],
            }}
          />
        );
      default:
        return '--';
    }
  };

  const renderUrgency = (urgency: string) => {
    const urgencyKey = urgency.toUpperCase();
    const urgencyColors = {
      HIGH: 'error',
      MEDIUM: 'warning',
      LOW: 'success',
    };

    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <ColorDot color={urgencyColors[urgencyKey]} />
        <span>{t(`tickets:table:urgency:${urgencyKey}`)}</span>
      </Box>
    );
  };

  const renderContact = (contact: string | null) => {
    if (contact === null) {
      return '--';
    }

    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <TableAvatar>{contact.match(/\b(\w)/g)?.join('')}</TableAvatar>
        <Typography>{contact}</Typography>
      </Box>
    );
  };

  const getTicketDefaults = () => {
    const ticket = tickets.find((ticket) => ticket.id === ticketToEdit);

    if (!ticket) {
      return undefined;
    }

    return {
      id: ticket.id,
      relatedError: ticket.relatedError?.messageId,
      title: ticket.title,
      description: ticket.description,
      serviceProvider: ticket.serviceProvider,
      urgency: ticket.urgency,
      assignee: ticket.serviceProvider === 'InternalService' ? ticket.assignedUser.id : '',
      contactPerson: ticket.serviceProvider === 'ExternalService' ? ticket.assignedUser.id : '',
      attachments: ticket.fileAttachments || [],
      includedErrorProtocol: false,
      timePeriodFrom: null,
      timePeriodTo: null,
    };
  };

  const columns: readonly Column[] = [
    { id: 'ticketId', label: t('tickets:table:header:ticketId'), align: 'left', minWidth: 170 },
    { id: 'title', label: t('tickets:table:header:title'), align: 'left', minWidth: 100 },
    {
      id: 'machine',
      label: t('tickets:table:header:machine'),
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'urgency',
      label: t('tickets:table:header:urgency'),
      minWidth: 170,
      align: 'left',
      format: (value) => renderUrgency(value as string),
    },
    {
      id: 'serviceProvider',
      label: t('tickets:table:header:serviceProvider'),
      minWidth: 170,
      align: 'left',
      format: (value) => t(`tickets:ticketDetails:${value}`),
    },

    {
      id: 'contact',
      label: t('tickets:table:header:contact'),
      minWidth: 170,
      align: 'left',
      format: (value) => renderContact(value as string),
    },
    {
      id: 'created',
      label: t('tickets:table:header:created'),
      minWidth: 170,
      align: 'left',
      format: (value) => formatDate(value as string, language, dateFormat),
    },

    {
      id: 'status',
      label: t('tickets:table:header:status'),
      minWidth: 170,
      align: 'left',
      format: (value) => renderChip(value as TICKET_STATUS),
    },
    {
      id: 'actions',
      label: t('tickets:table:header:actions'),
      minWidth: 100,
      align: 'right',
    },
  ];

  useEffect(() => {
    setTableRows(generateRows(tickets));
  }, [tickets]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label='tickets table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      textTransform: 'uppercase',
                      color: (theme) => theme.palette.grey[100],
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={uuidv4()}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/service/${row.ticketId}`)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            color:
                              row.status === TICKET_STATUS.RESOLVED /* || row.status === TICKET_STATUS.CANCELED*/
                                ? (theme) => theme.palette.grey[80]
                                : (theme) => theme.palette.black[100],
                          }}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={ticketsTotal}
          rowsPerPage={pagination.rowsPerPage}
          rowsPerPageOptions={[25, 50]}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {userAccess.creat_edit_tickets_allowed && featureFlags?.includes('OnlineMode') && (
        <NewTicket
          open={!!ticketToEdit}
          onClose={() => {
            setTicketToEdit(null);
          }}
          actionType='Update'
          ticketDefaultValues={getTicketDefaults()}
        />
      )}

      <DeleteDialog
        open={!!ticketToCancel}
        onConfirm={handleCancelTicket}
        onClose={() => setTicketToCancel(null)}
        dialogTitle={t('tickets:ticketDetails:cancelDialog:title')}
        dialogConfirmLabel={t('tickets:ticketDetails:cancelDialog:confirmLabel')}
        dialogText={
          <Trans
            i18nKey='tickets:ticketDetails:cancelDialog:text'
            values={{
              ticketId: ticketToCancel,
            }}
            components={{ bold: <strong /> }}
          />
        }
      />
    </>
  );
};

export default TicketsTable;
