/*-----------------User Types-----------------*/
export type TAuthUser = {
  tenantId: string;
  organizationId: string;
  organizationPermissions: string[];
  localAccountId: string;
  name?: string;
};

export type TUser = {
  userId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  phone: string;
  roles: TUserRole[];
  organizationId: {
    organizationId: string;
    name: string;
    domain: string | null;
  };
};

export type TUserRole = {
  roleId: string;
  name: string;
  organization: {
    organizationId: string;
    name: string;
    domain: string | null;
  };
  permissions: string[];
  i18nKey?: string;
};

export enum USER_ROLE {
  ADMINISTRATOR = 'OrganizationAdministrator',
  TRAIN_USER = 'TrainUser',
  INFRASTRUCTURE_USER = 'InfrastructureUser',
  TRAIN_INFRASTRUCTURE_USER = 'TrainAndInfrastructureUser',
  DEVICE_ADMINISTRATOR = 'DeviceAdministrator',
}

/*-----------------Lab Types-----------------*/
export type TMachineState = 'running' | 'stopped' | 'waiting' | 'unknown';
export type TTimeFilterValue = 'today' | 'yesterday' | 'currentWeek' | 'currentMonth' | 'currentYear' | string;
export type TUrgency = 'Low' | 'Medium' | 'High';
export type TServiceProvider = 'InternalService' | 'ExternalService';

export type TMachine = {
  id: string;
  name: string;
  type: string;
  typeDescription: string;
  version: string;
  configuration: string;
  serialNumber: string;
  ipAddress: {
    address: string;
    port: number;
  };
  activeNotifications: {
    errorsCount: number;
    warningsCount: number;
  };
  state: TMachineState;
};

export type TTelemetryData = {
  controlVoltage: number;
  overrideAxis: number;
  overrideSpindle: number;
  programAutomatic: number;
  programManual: number;
};

export type TSpindleData = {
  name: string;
  currentSpeed: number;
  targetSpeed: number;
  load: number;
};

export type TAvailabilityGraph = {
  state: string;
  availability: number;
};

export type TAvailabilityHistogram = {
  status: string;
  timeSpan: string;
  sourceTimestamp: string;
};

export enum TICKET_STATUS {
  NEW = 'New',
  PROGRESS = 'InProgress',
  RESOLVED = 'Resolved',
  CANCELED = 'Cancelled',
  PENDING = 'PendingApproval',
}

export enum ISSUE_STATUS {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
}

export enum NOTIFICATION_TYPE {
  ERROR = 'Error',
  WARNING = 'Warning',
}

export type TNotification = {
  messageId: string;
  message: string;
  issueCode: {
    type: NOTIFICATION_TYPE;
    code: string;
  };
  startedAt: string;
  duration: string | null;
  issueStatus: ISSUE_STATUS;
  ticketsDetails: {
    id: string;
    status: TICKET_STATUS;
    title: string;
  }[];
  machineSerialNumbers?: string[];
  searchPhrase?: string;
};

export type TServiceDocuments = {
  machineId: string;
  documentName: string;
  uploadedByUser: string;
  uploadedDate: string;
};

export type TKnowledgeFile = {
  name: string;
  description: string;
  size: number;
};

export type TOverviewGroup = 'issues' | 'process';

export type TComment = {
  id: string;
  comment: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: {
    id: string;
    displayName: string;
    emailAddress: string;
  };
};

export type TTicket = {
  id: string;
  machineDetails: {
    id: string;
    name: string;
    type: string;
    serialNumber: string;
    lastMaintenance: string;
  };
  createdByUser: {
    id: string;
    displayName: string;
    emailAddress: string;
  };
  updatedByUser: {
    id: string;
    displayName: string;
    emailAddress: string;
  };
  approvedByUser: {
    id: string;
    displayName: string;
    emailAddress: string;
  };
  createdAt: string;
  updatedAt: string;
  state: TICKET_STATUS;
  title: string;
  description: string;
  serviceProvider: TServiceProvider;
  urgency: TUrgency;
  comments: TComment[];
  fileAttachments: {
    fileName: string;
    fileSize: number;
  }[];
  assignedUser: {
    id: string;
    displayName: string;
    emailAddress: string;
  };
  relatedError: {
    messageId: string;
    message: string;
  };
  relatedErrorMessage: string;
};
