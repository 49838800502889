import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ContextMenu, { ContextButton } from '../../../components/ContextMenu';
import { ISSUE_STATUS, TICKET_STATUS, NOTIFICATION_TYPE, TNotification } from '../../../types';
import { formatDate } from '../../../helpers';

/* ------- Styles ------- */
const statusBaseStyle = {
  fontSize: '14px',
  fontWeight: 500,
};

/* ------- Types ------- */
interface Column {
  id: 'issueCode' | 'message' | 'started' | 'duration' | 'issueStatus' | 'ticketsDetails' | 'actions';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  format?: (value: number | string | object[]) => string | JSX.Element;
}

interface Data {
  issueCode: string;
  message: string;
  started: string;
  duration: string;
  issueStatus: ISSUE_STATUS;
  ticketsDetails: TNotification['ticketsDetails'] | null;
  actions: JSX.Element | null;
}

interface ActionsProps {
  issueStatus: ISSUE_STATUS | null;
  onCreateTicket: () => void;
}

interface INotificationsTableProps {
  notifications: TNotification[];
  notificationsTotal: number;
  pagination: {
    page: number;
    onChangePage: (value: number) => void;
    rowsPerPage: number;
    onChangeRowsPerPage: (value: number) => void;
  };
  onCreateTicket?: (notificationId: string) => void;
}

/* ------- Components ------- */
const Actions: React.FC<ActionsProps> = ({ issueStatus, onCreateTicket }) => {
  const [contextOpen, setContextOpen] = useState<boolean>(false);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const getAllowedActions = () => {
    return [
      { name: 'Create Ticket', callback: onCreateTicket },
      { name: 'View Issue Details', disabled: true },
    ];
  };

  return issueStatus === ISSUE_STATUS.ACTIVE ? (
    <Box>
      <ContextButton
        ref={menuAnchorRef}
        testId='tableActions'
        menuOpen={contextOpen}
        handleOpenMenu={() => setContextOpen(true)}
      >
        <MoreHorizIcon />
      </ContextButton>
      <ContextMenu
        menuOpen={contextOpen}
        anchorEl={menuAnchorRef.current}
        handleCloseMenu={() => setContextOpen(false)}
        contextActions={getAllowedActions()}
        hoverColor={(theme) => theme.palette.others.blue[10]}
      />
    </Box>
  ) : null;
};

const NotificationsTable: React.FC<INotificationsTableProps> = ({
  notifications,
  notificationsTotal,
  pagination,
  onCreateTicket,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    pagination.onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    pagination.onChangeRowsPerPage(parseInt(event.target.value, 10));
    pagination.onChangePage(0);
  };

  const createData = (issueCode, message, started, duration, issueStatus, ticketsDetails, actions): Data => {
    return { issueCode, message, started, duration, issueStatus, ticketsDetails, actions };
  };

  const generateRows: (data: TNotification[]) => Data[] = (data) => {
    return data.map(({ issueCode, message, startedAt, duration, issueStatus, ticketsDetails }) =>
      createData(
        issueCode.code,
        message,
        startedAt,
        duration || '--',
        issueStatus,
        ticketsDetails,
        <Actions issueStatus={issueStatus} onCreateTicket={() => onCreateTicket && onCreateTicket(issueCode.code)} />,
      ),
    );
  };

  const renderChip = (status: ISSUE_STATUS | TICKET_STATUS) => {
    switch (status) {
      case ISSUE_STATUS.ACTIVE:
        return (
          <Chip
            label={t('lab:detailView:notifications:table:status:active')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.red[100],
              border: '1px solid rgba(205,51,49,.3)',
              backgroundColor: (theme) => theme.palette.red[10],
            }}
          />
        );
      case ISSUE_STATUS.INACTIVE:
        return (
          <Chip
            label={t('lab:detailView:notifications:table:status:inactive')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.black[60],
              border: '1px solid rgba(134, 135, 137, .3)',
              backgroundColor: (theme) => theme.palette.grey[10],
            }}
          />
        );
      case TICKET_STATUS.NEW:
        return (
          <Chip
            label={t('lab:detailView:notifications:table:status:new')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.blue[100],
              border: '1px solid rgba(1, 121, 231, .3)',
              backgroundColor: (theme) => theme.palette.others.blue[10],
            }}
          />
        );
      case TICKET_STATUS.PROGRESS:
        return (
          <Chip
            label={t('lab:detailView:notifications:table:status:progress')}
            sx={{
              color: (theme) => theme.palette.others.brown[100],
              border: '1px solid rgba(255, 173, 0, .3)',
              backgroundColor: (theme) => theme.palette.others.orange[10],
              fontSize: '14px',
              fontWeight: 500,
            }}
          />
        );
      case TICKET_STATUS.RESOLVED:
        return (
          <Chip
            label={t('lab:detailView:notifications:table:status:resolved')}
            sx={{
              ...statusBaseStyle,
              color: (theme) => theme.palette.others.green[100],
              border: '1px solid rgba(74, 166, 105, 1)',
              backgroundColor: (theme) => theme.palette.others.green[10],
            }}
          />
        );
      default:
        return '--';
    }
  };

  const renderTicketStati = (tickets: TNotification['ticketsDetails']) => {
    if (!tickets) {
      return '--';
    }

    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        {tickets.map((ticket) => (
          <Button
            key={ticket.id}
            variant='text'
            sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            title={ticket.title}
            onClick={() => navigate(`/service/${ticket.id}`)}
          >
            {renderChip(ticket.status)}
          </Button>
        ))}
      </Box>
    );
  };

  const formatDuration = (duration: string) => {
    if (duration !== '--') {
      const timeValues = duration.split(':');
      const roundedSeconds =
        timeValues[2].indexOf('.') > 0 ? timeValues[2].slice(0, timeValues[2].indexOf('.')) : timeValues[2];
      return `${timeValues[0]}:${timeValues[1]}:${roundedSeconds}`;
    }

    return duration;
  };

  const columns: readonly Column[] = [
    { id: 'issueCode', label: t('lab:detailView:notifications:table:header:issueCode'), align: 'left', minWidth: 170 },
    { id: 'message', label: t('lab:detailView:notifications:table:header:message'), align: 'left', minWidth: 100 },
    {
      id: 'started',
      label: t('lab:detailView:notifications:table:header:started'),
      minWidth: 170,
      align: 'left',
      format: (value) => formatDate(value as string, 'de', dateFormat),
    },
    {
      id: 'duration',
      label: t('lab:detailView:notifications:table:header:duration'),
      minWidth: 170,
      align: 'left',
      format: (value) => formatDuration(value as string),
    },
    {
      id: 'issueStatus',
      label: t('lab:detailView:notifications:table:header:issueStatus'),
      minWidth: 170,
      align: 'left',
      format: (value) => renderChip(value as ISSUE_STATUS),
    },
    {
      id: 'ticketsDetails',
      label: t('lab:detailView:notifications:table:header:ticketStatus'),
      minWidth: 170,
      align: 'left',
      format: (value) => renderTicketStati(value as TNotification['ticketsDetails']),
    },
    {
      id: 'actions',
      label: t('lab:detailView:notifications:table:header:actions'),
      minWidth: 100,
      align: 'right',
    },
  ];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label='notifications table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    textTransform: 'uppercase',
                    color: (theme) => theme.palette.grey[100],
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {generateRows(notifications).map((row) => {
              return (
                <TableRow hover tabIndex={-1} key={uuidv4()}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color:
                            row.issueStatus === ISSUE_STATUS.INACTIVE
                              ? (theme) => theme.palette.grey[80]
                              : (theme) => theme.palette.black[100],
                          backgroundColor:
                            notifications.find((notification) => notification.issueCode.code === row.issueCode)
                              ?.issueCode.type === NOTIFICATION_TYPE.ERROR && row.issueStatus === ISSUE_STATUS.ACTIVE
                              ? (theme) => theme.palette.red[10]
                              : 'initial',
                        }}
                      >
                        {column.id === 'issueCode' &&
                          (notifications.find((notification) => notification.issueCode.code === value)?.issueCode
                            .type === NOTIFICATION_TYPE.ERROR ? (
                            <ErrorOutlineIcon
                              sx={{
                                verticalAlign: 'bottom',
                                marginRight: '8px',
                                color: (theme) =>
                                  row.issueStatus === ISSUE_STATUS.ACTIVE
                                    ? theme.palette.red[100]
                                    : theme.palette.red[60],
                              }}
                            />
                          ) : (
                            <WarningAmberIcon
                              sx={{
                                verticalAlign: 'bottom',
                                marginRight: '8px',
                                color: (theme) =>
                                  row.issueStatus === ISSUE_STATUS.ACTIVE
                                    ? theme.palette.others.orange[100]
                                    : theme.palette.others.orange[60],
                              }}
                            />
                          ))}
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={notificationsTotal}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={[25, 50]}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default NotificationsTable;
