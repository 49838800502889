import { createSelector } from 'reselect';
import { RootState } from '..';

const userState = (state: RootState) => state.user;

export const selectUser = createSelector(userState, (user) => user.user);
export const selectUsers = createSelector(userState, (user) => user.users);
export const selectAuthUser = createSelector(userState, (user) => user.authUser);
export const selectUserId = createSelector(userState, (user) => user.authUser?.localAccountId);
export const selectUserRoles = createSelector(userState, (user) => user.roles);
export const selectActionFlags = createSelector(userState, (user) => user.actionFlags);
export const selectFeatureFlags = createSelector(userState, (user) => user.featureFlags);
